body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body::before{
  display:block;
  content: "";
  height: 30px;
}
.navbar{
  color: #E7F2F4;
}
li{
  color:aqua;
}
body{
  background-image: url(../public/images/urban-landscape-background\ 2.png);
  /* width: 100vw;
  height: 100vh;
  background-repeat: no-repeat; */
  background-size: cover;
}
.card{
  background-color:#a0b19d;
}
.btn{
  background-color: aquamarine;
}

.view {
  margin: auto;
  width: 600px;
}

.wrapper {
  position: relative;
  overflow: auto;
  border: 1px solid black;
  white-space: nowrap;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
}

.first-col {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  left: 0px;
  background-color: white;
}

.second-col {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  left: 100px;
}

@media only screen and (max-width: 600px) {
  .moreadimage {
    display: none;
  }
}
@media only screen and (max-width: 800px) {
  .onemoreadimage {
    display: none;
  }
}

.image-container {
  position: relative;
  width: 100%;
}

/* Make the image responsive */
.image-container img {
  width: 100%;
  height: auto;
}

/* Style the button and place it in the middle of the container/image */
.image-container .btn {
  position: absolute;
  top: 86%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color:rgb(53, 106, 133);
  color: white;
  font-size: 16px;
  padding:  6px 6px;
   /* 12px 12px; */
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width:65%;
  border-radius:5px;
}

.image-container .homebtn {
  position: absolute;
  top: 86%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color:rgb(53, 106, 133);
  color: white;
  font-size: 16px;
  padding:  6px 6px;
   /* 12px 12px; */
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width:40%;
  border-radius:5px;
}
.image-container .btn:hover {
  background-color: black;
  color: white;
}

@media print {
  .pagebreak {
      clear: both;
      min-height: 1px;
      page-break-after: always;
  }
}

/* The message box is shown when the user clicks on the password field */
#message {
  display:none;
  background: #f1f1f1;
  color: #000;
  position: relative;
  padding: 20px;
  margin-top: 10px;
}

#message p {
  padding: 10px 35px;
  font-size: 18px;
}

/* Add a green text color and a checkmark when the requirements are right */
.valid {
  color: green;
}

.valid:before {
  position: relative;
  left: -35px;
  /* content: "&#10004;"; */
}

/* Add a red text color and an "x" icon when the requirements are wrong */
.invalid {
  color: red;
}

.invalid:before {
  position: relative;
  left: -35px;
  /* content: "&#10006;"; */
}

/* for palmeeup */

@import url("https://fonts.googleapis.com/css?family=Roboto");
@-webkit-keyframes come-in {
  0% {
    -webkit-transform: translatey(100px);
            transform: translatey(100px);
    opacity: 0;
  }
  30% {
    -webkit-transform: translateX(-50px) scale(0.4);
            transform: translateX(-50px) scale(0.4);
  }
  70% {
    -webkit-transform: translateX(0px) scale(1.2);
            transform: translateX(0px) scale(1.2);
  }
  100% {
    -webkit-transform: translatey(0px) scale(1);
            transform: translatey(0px) scale(1);
    opacity: 1;
  }
}
@keyframes come-in {
  0% {
    -webkit-transform: translatey(100px);
            transform: translatey(100px);
    opacity: 0;
  }
  30% {
    -webkit-transform: translateX(-50px) scale(0.4);
            transform: translateX(-50px) scale(0.4);
  }
  70% {
    -webkit-transform: translateX(0px) scale(1.2);
            transform: translateX(0px) scale(1.2);
  }
  100% {
    -webkit-transform: translatey(0px) scale(1);
            transform: translatey(0px) scale(1);
    opacity: 1;
  }
}
* {
  margin: 0;
  padding: 0;
}

/* html, body {
  background: #eaedf2;
  font-family: 'Roboto', sans-serif;
} */

.floating-container {
  position: fixed;
  width: 100px;
  height: 100px;
  bottom: 0;
  right: 0;
  margin: 35px 25px;
}
.floating-container:hover {
  height: 300px;
}
.floating-container:hover .floating-button {
  box-shadow: 0 10px 25px rgba(44, 179, 240, 0.6);
  -webkit-transform: translatey(5px);
          transform: translatey(5px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.floating-container:hover .element-container .float-element:nth-child(1) {
  -webkit-animation: come-in 0.4s forwards 0.2s;
          animation: come-in 0.4s forwards 0.2s;
}
.floating-container:hover .element-container .float-element:nth-child(2) {
  -webkit-animation: come-in 0.4s forwards 0.4s;
          animation: come-in 0.4s forwards 0.4s;
}
.floating-container:hover .element-container .float-element:nth-child(3) {
  -webkit-animation: come-in 0.4s forwards 0.6s;
          animation: come-in 0.4s forwards 0.6s;
}
.floating-container .floating-button {
  position: absolute;
  width: 65px;
  height: 65px;
  background: #2cb3f0;
  bottom: 0;
  border-radius: 50%;
  left: 0;
  right: 0;
  margin: auto;
  color: white;
  line-height: 65px;
  text-align: center;
  font-size: 23px;
  z-index: 100;
  box-shadow: 0 10px 25px -5px rgba(44, 179, 240, 0.6);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.floating-container .float-element {
  position: relative;
  display: block;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 15px auto;
  color: white;
  font-weight: 500;
  text-align: center;
  line-height: 50px;
  z-index: 0;
  opacity: 0;
  -webkit-transform: translateY(100px);
          transform: translateY(100px);
}
.floating-container .float-element .material-icons {
  vertical-align: middle;
  font-size: 16px;
}
.floating-container .float-element:nth-child(1) {
  background: #42A5F5;
  box-shadow: 0 20px 20px -10px rgba(66, 165, 245, 0.5);
}
.floating-container .float-element:nth-child(2) {
  background: #4CAF50;
  box-shadow: 0 20px 20px -10px rgba(76, 175, 80, 0.5);
}
.floating-container .float-element:nth-child(3) {
  background: #FF9800;
  box-shadow: 0 20px 20px -10px rgba(255, 152, 0, 0.5);
}


/*  */

/* palmeeeup */
.applink{
  text-decoration:none;
  color:green;
}
.fixedChatHeader{
  height: 7rem;
  /* 7rem; */
  top: 0px;
  /* 58px; */
  width:100vw;
  background-color: #070033;
   /* #070033; */
  position:fixed;
}

.fixed-content {
  top: 7rem;
  /* 10rem; */
  bottom:5%;
  width:100%;
  padding-top: 30px;
  background-color:  #0a0a38;
  /* #070033; */
  position:fixed;
  overflow-y:scroll;
  overflow-x:hidden;

  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
}
.afterfixed-content {
  top: 95%;
  width:100%;
  height: 5%;
  background-color:  #070033;
  /* bottom:0%; */
  position:fixed;
  overflow-y:scroll;
  overflow-x:hidden;

}

.chatinput {
  top: 95%;
  width:80%;
  height: 5%;
  /* bottom:0%; */
  position:fixed;
  overflow-y:scroll;
  overflow-x:hidden;
  display: flex;
  float: inline-start;
}

.sendbutton {
  top: 95%;
  width:20%;
  height: 100%;
  /* bottom:0%; */
  overflow-y:scroll;
  overflow-x:hidden;
  display: flex;
  float:inline-end;
}
.center {
  /* margin: 0 ;
  padding-bottom: 50px;
  padding-top: 50px;
  width:50%; */
  /* position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
  margin-top: 30px;
  min-height: 100vh;
  height: auto;
  color:white;
  background-color: #070033;
  text-align: center ;
}
.centerTop {
  height: auto;
  color:white;
  /* width:100vw; */
  background-color: #070033;
  text-align: start ;
}
.centerCenter {
  margin: 0 ;
  padding-bottom: 50px;
  padding-top: 50px;
  width:50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color:white;
  background-color: #070033;
  text-align: center ;
}
.loadingcenterCenter{
  margin: 0 ;
  padding-bottom: 50px;
  padding-top: 50px;
  border-radius: 50%;
  width:50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color:#070033;
  /* background-color: white; */
  text-align: center ;
}
label{
  /* height: 7em; */
} 
/* input{
  height: 2em;
  width: 30vw;
  min-width: min-content;
} */

.addtaginput{
  padding: 10px;
  height: 2em;
  width: 30vw;
  border-radius: 45px;
  min-width: min-content;
  margin-right: 20px;
}
.addtaginputbutton{
  padding: 5px;
  border-radius: 45px;
  background-color:#089A6E;
  color:white;
}
.contentcenter{

}
.loginButton {
  width: 25vw;
  border-radius: 50px;
  height: 4vh;
}
.trendingTags{
  display: inline-block;
  background-color:  #F36D21;
  ;
  margin: 5px;
  padding: 5px;
  border-radius: 15px
}
.activeTags{
  display: inline-block;
  background-color: #15C69B;
  ;
  margin: 5px;
  padding: 5px;
  border-radius: 15px
}
.matchingTags{
  display: inline-block;
  background-color:  #F36D21;
  ;
  margin: 5px;
  padding: 5px;
  border-radius: 15px
}
.tagUsersList{
  text-align: start;
  margin-left: 2vw;
}
.dashboardImage{
  height: 15vw;
  width: 15vw;
  border-radius: 50%;
  vertical-align: middle;
}
.listImage{
  width: 5vw;
  border-radius: 50%;
  vertical-align: middle;
}
.profileImage{
  /* width: 50vw; */
  border-radius: 10%;
  vertical-align: middle;
}
.tabletags{
  width: 30%;
}
.profilePageImage{
  height: 30vh;
  border-radius: 10%;
  vertical-align: middle;
  margin-bottom: 50px;
  margin-top: 30px;
}

.tablepix{
  width: 20%;
  text-align: center;

}
.tablename{
  /* width: 50%; */
}
.tablerow{
  /* background-color: brown; */
  margin-left:auto;
  margin-right:auto;
  border-color: white;
  border-bottom: #F36D21;
  /* border-bottom:2px solid white;  */
}

.backicon{
 font-size:48px;
 color:white;
 margin-right: 55px;
 text-align: start;

}
.backtitle{
  font-size:48px;
  text-align: center;
  color:white;

 }
 .chatList{
  text-align: start;
  margin-left: 10vw;
  color: white;
  /* text-decoration:none; */
 }
 .chatListtext{
  text-align: start;
  margin-left: 10vw;
  color: white;
  /* text-decoration:none; */
 }
 /* palmeeup */